import React from "react"

const Loading = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="96"
      height="96"
      viewBox="0 0 256 256"
      fill="none"
    >
      <g clipPath="url(#clip0)">
        <rect width="256" height="256" fill="current" />
        <path
          d="M128.014 75.9442C129.871 77.2203 131.735 78.6222 133.55 80.1128C133.941 80.4344 134.417 80.6047 134.905 80.6088C135.128 80.6107 135.355 80.5778 135.576 80.5072C136.265 80.2868 136.789 79.7358 136.976 79.0369L150.901 27.6524C151.44 25.6628 151.153 23.6239 150.093 21.9095C149.03 20.1948 147.329 19.0289 145.306 18.625C143.442 18.2558 141.552 18.6304 139.977 19.6847C138.399 20.7388 137.327 22.3445 136.957 24.2044L127.126 73.7649C126.959 74.6065 127.31 75.4621 128.014 75.9442Z"
          fill="black"
        >
          <animate
            attributeName="fill"
            dur=".8s"
            values="#0E7874;black;#0E7874"
            repeatCount="indefinite"
            begin="0.1"
          />
        </path>
        <path
          d="M147.074 94.1112C148.209 95.5737 149.31 97.0849 150.38 98.6504C150.865 99.3593 151.631 99.8026 152.482 99.8659C152.543 99.8704 152.605 99.8739 152.666 99.8743C153.439 99.8808 154.173 99.5662 154.703 98.9957L193.343 57.5868C196.082 54.6501 196.143 50.0284 193.395 46.9593C191.835 45.2225 189.585 44.2766 187.282 44.3433C184.979 44.4172 182.834 45.4957 181.406 47.3049L147.093 90.7004C146.302 91.6986 146.293 93.0998 147.074 94.1112Z"
          fill="black"
        >
          <animate
            attributeName="fill"
            dur=".8s"
            values="#0E7874;black;#0E7874"
            repeatCount="indefinite"
            begin="0.2"
          />
        </path>
        <path
          d="M219.946 102.305C219.495 99.9134 218.017 97.8421 215.892 96.6238C213.747 95.3912 211.176 95.1569 208.843 95.9911L162.321 112.575C161.448 112.887 160.733 113.55 160.363 114.395C159.993 115.242 159.99 116.219 160.357 117.076C161.041 118.677 161.702 120.311 162.338 121.986C162.843 123.325 164.106 124.174 165.468 124.186C165.747 124.188 166.027 124.156 166.306 124.086L213.731 112.23C217.989 111.168 220.79 106.781 219.946 102.305Z"
          fill="#0E7874"
        >
          <animate
            attributeName="fill"
            dur=".8s"
            values="#0E7874;black;#0E7874"
            repeatCount="indefinite"
            begin="0.3"
          />
        </path>
        <path
          d="M210.548 154.151L173.324 143.606C172.241 143.301 171.103 143.569 170.278 144.326C169.449 145.082 169.079 146.195 169.287 147.304C169.727 149.673 170.128 152.094 170.489 154.567C170.655 155.711 171.423 156.683 172.497 157.102L205.252 169.907C206.195 170.278 207.193 170.466 208.192 170.474C209.434 170.485 210.676 170.219 211.813 169.679C213.887 168.697 215.418 166.96 216.169 164.647C216.863 162.495 216.654 160.211 215.585 158.211C214.514 156.209 212.726 154.767 210.548 154.151Z"
          fill="black"
        >
          <animate
            attributeName="fill"
            dur=".8s"
            values="#0E7874;black;#0E7874"
            repeatCount="indefinite"
            begin="0.4"
          />
        </path>
        <path
          d="M166.375 186.676L164.734 186.676C157.556 186.678 155.101 209.238 143.207 209.241L97.6769 209.253C93.3707 209.254 91.1126 205.973 91.1113 201.256L91.0809 86.4083C91.079 78.8202 99.0774 79.0234 99.0764 74.9209L99.0761 74.512C99.0757 72.8703 97.4353 72.0498 94.9744 72.0505L69.1339 72.0572C66.6728 72.058 65.0313 72.8792 65.0317 74.5209L65.032 74.9297C65.033 79.0323 72.4157 78.8252 72.4179 86.4133L72.4487 203.312C72.4506 210.491 65.0679 210.903 65.069 214.799V215.209C65.0694 216.644 66.7113 217.67 69.1725 217.67L158.385 217.646C161.461 217.645 162.896 216.004 163.511 212.928L168.632 190.366C169.247 187.905 168.221 186.676 166.375 186.676Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="256" height="256" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Loading
