import React from "react"
import PropTypes from "prop-types"
import MembershipPicker from "./membership-picker"
import { usePricing } from "../../context/pricing-context"

function MembershipPickerGroup({ memberships, onPlanSelect }) {
  const pricing = usePricing()

  return (
    <div className="w-full flex flex-col md:flex-row space-y-4 md:space-y-0 space-x-0 md:space-x-4 justify-center">
      {memberships?.map((membership) => {
        const purchaseButtonText =
          membership.purchase_button_text?.text ?? "Purchase Now"

        if (membership.plan === "post_loss_light") {
          return (
            <MembershipPicker
              key={membership.plan}
              onPlanSelect={onPlanSelect}
              content={membership}
              isFree={true}
              freePriceText="Free"
              purchaseButtonText={purchaseButtonText}
            />
          )
        }

        if (membership.plan === "post_lost_consult") {
          return (
            <MembershipPicker
              key={membership.plan}
              onPlanSelect={onPlanSelect}
              content={membership}
              isFree={true}
              freePriceText="By Consult"
              purchaseButtonText={purchaseButtonText}
            />
          )
        }

        if (membership.plan === "pre_planning_starter") {
          return (
            <MembershipPicker
              key={membership.plan}
              onPlanSelect={onPlanSelect}
              content={membership}
              isFree={true}
              freePriceText="Free"
              purchaseButtonText={purchaseButtonText}
            />
          )
        }

        if (membership.plan === "pre_planning_premium") {
          return (
            <MembershipPicker
              key={membership.plan}
              onPlanSelect={onPlanSelect}
              lifetimePrice={pricing?.premiumLifetime}
              content={membership}
              isYearly
              purchaseButtonText={purchaseButtonText}
            />
          )
        }
        return null
      })}
    </div>
  )
}

MembershipPickerGroup.propTypes = {
  memberships: PropTypes.array,
  onPlanSelect: PropTypes.func,
  showPeriodToggle: PropTypes.bool,
}
MembershipPickerGroup.defaultProps = {
  plansToShow: ["pre_planning_starter", "pre_planning_premium"],
  showPeriodToggle: true,
}

export default MembershipPickerGroup
