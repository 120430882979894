import { gql } from "@apollo/client"

const MUTATION_PREPLAN_FORM_SAVE_ANSWERS = gql`
  mutation savePrePlanFormAnswers(
    $plan_id: ID!
    $task_id: ID!
    $form_key: String!
    $form_data: Json!
  ) {
    savePrePlanFormAnswers(
      input: {
        plan_id: $plan_id
        task_id: $task_id
        form_key: $form_key
        form_data: $form_data
      }
    ) {
      plan_id
      form_key
      answers
    }
  }
`

const MUTATION_REMOVE_FINISH_SETUP = gql`
  mutation removeFinishSetup($id: ID!) {
    removeFinishSetup(id: $id) {
      id
    }
  }
`

const MUTATION_SKIP_STEP = gql`
  mutation skipStep($id: ID!, $step: Int!) {
    skipStep(id: $id, step: $step) {
      id
      step
    }
  }
`

const MUTATION_ARCHIVE_PREPLANNINGPLAN = gql`
  mutation archivePreplanningPlan($id: ID!) {
    archivePreplanningPlan(id: $id) {
      id
    }
  }
`

export {
  MUTATION_PREPLAN_FORM_SAVE_ANSWERS,
  MUTATION_REMOVE_FINISH_SETUP,
  MUTATION_SKIP_STEP,
  MUTATION_ARCHIVE_PREPLANNINGPLAN,
}
