import numberToWords from "./number-to-words"

const actions = {
  POST_LOSS_PLANNING_LISTS: "post_loss_planning_lists",
  PRE_PLANNING_LISTS: "pre_planning_lists",
  CUSTOM_POST_LOSS_PLANNING_TASKS: "custom_post_loss_planning_tasks",
  CUSTOM_PRE_PLANNING_TASKS: "custom_pre_planning_tasks",
  TEAM_MEMBERS: "team_members",
  STORAGE_LIMIT: "storage_limit",
  ADD_COMMENTS: "add_comments",
}

const showPaywall = (alert, message, onFail) => {
  alert.error(message)
  if (onFail) {
    onFail()
  }
}

const showPayForExtraPrePlan = (onLimitExceeded) => {
  if (onLimitExceeded) {
    onLimitExceeded()
  }
}

const showLimitError = (alert, message) => {
  alert.error(message)
  return null
}

const POST_LOSS_PLAN_ERROR =
  "You've reached your limit of pre-plans! Please archive an existing plan to add a new one."

const checkAbilities = ({
  user,
  action,
  alert,
  teamOptions,
  files,
  onFail,
  onLimitExceeded,
}) => {
  switch (action) {
    case "post_loss_planning_lists":
      {
        let postLossListsCount = user?.plans?.filter(
          (plan) => plan?.intake_response?.scenario !== "preparing"
        ).length

        if (postLossListsCount >= user?.abilities?.post_loss_planning_lists) {
          if (
            user?.membership_plan === "premium" ||
            user?.membership_plan === "premium_subscription"
          ) {
            showLimitError(alert, POST_LOSS_PLAN_ERROR)
          } else {
            showPaywall(
              alert,
              "The number of after-loss plans exceeds the limit of this plan. Upgrade your plan to increase your limit",
              onFail
            )
          }
        } else {
          return true
        }
      }
      break

    case "pre_planning_lists":
      {
        let prePlanningListsCount = user?.prePlanningPlans?.length

        if (prePlanningListsCount >= user?.abilities?.pre_planning_lists) {
          if (
            user?.membership_plan === "premium" ||
            user?.membership_plan === "premium_subscription"
          ) {
            showPayForExtraPrePlan(onLimitExceeded)
          } else {
            showPaywall(
              alert,
              "Upgrade to Premium to create additional plans. Upgrade now",
              onFail
            )
          }
        } else {
          return true
        }
      }
      break

    case "custom_post_loss_planning_tasks":
      if (!user?.abilities?.custom_post_loss_planning_tasks) {
        showPaywall(
          alert,
          "Upgrade your plan to add custom after-loss planning tasks",
          onFail
        )
      } else {
        return true
      }

      break

    case "custom_pre_planning_tasks":
      if (!user?.abilities?.custom_pre_planning_tasks) {
        showPaywall(
          alert,
          "Upgrade your plan to add custom pre-planning tasks",
          onFail
        )
      } else {
        return true
      }
      break

    case "team_members":
      {
        let pendingMembers =
          teamOptions?.members?.getCollaborators?.pending ||
          teamOptions?.members
        let pendingCollaboratorsCount = pendingMembers?.filter(
          (member) => member?.role === "Admin" && member?.timing === "now"
        ).length
        let pendingBeneficiariesCount = pendingMembers?.filter(
          (member) => member?.role === "Admin" && member?.timing === "post-loss"
        ).length

        let collaboratorsAllowed = user?.abilities?.collaborators
        let beneficiariesAllowed = user?.abilities?.beneficiaries

        let message = ""

        if (
          (teamOptions?.timing === "now" &&
            pendingCollaboratorsCount + 1 > collaboratorsAllowed) ||
          (teamOptions?.timing === "post-loss" &&
            pendingBeneficiariesCount + 1 > beneficiariesAllowed)
        ) {
          if (
            user?.membership_plan === "premium" ||
            user?.membership_plan === "premium_subscription"
          ) {
            let allowedText =
              teamOptions?.timing === "now"
                ? numberToWords(collaboratorsAllowed) + " collaborators"
                : numberToWords(beneficiariesAllowed) + " beneficiaries"

            message = `You may only add ${allowedText} to a plan. Please remove one in order to add a new one.`

            showLimitError(alert, message)
          } else {
            if (teamOptions?.timing === "post-loss") {
              message = "Upgrade your plan to add more beneficiaries"
            } else {
              message = "Upgrade your plan to add more collaborators"
            }
            showPaywall(alert, message, onFail)
          }
        } else if (
          teamOptions.is_preplanning_plan &&
          teamOptions?.timing === "now" &&
          (user?.membership_plan !== "premium" &&
            user?.membership_plan !== "premium_subscription")
        ) {
          message =
            "Adding a collaborator on your preplan is a Premium Feature. Upgrade your plan to add collaborators"
          showPaywall(alert, message, onFail)
        } else {
          return true
        }
      }
      break

    case "storage_limit":
      {
        let uploadedFilesSize = user?.files?.reduce(
          (acc, file) => acc + parseInt(file?.size),
          0
        )

        let newFilesSize = files?.reduce(
          (acc, file) => acc + parseInt(file?.size),
          0
        )

        if (
          (uploadedFilesSize + newFilesSize) / 1000 >=
          parseInt(user?.abilities?.storage_limit)
        ) {
          if (
            user?.membership_plan === "premium" ||
            user?.membership_plan === "premium_subscription"
          ) {
            showLimitError(alert, "Storage limit exceeded")
          } else {
            showPaywall(
              alert,
              "Storage limit exceeded. Upgrade your plan to increase your storage limit",
              onFail
            )
          }
        } else {
          return true
        }
      }
      break

    case "add_comments":
      if (!user?.abilities?.add_comments) {
        showPaywall(alert, "Upgrade your plan to add comments", onFail)
      } else {
        return true
      }

      break

    default:
      return true
  }
}

export { actions, checkAbilities }
