import React from "react"
import PropTypes from "prop-types"
import parse from "html-react-parser"
import Button from "../../components/button"
import { navigate } from "gatsby"
import { useUser } from "../../context/user-context";

function MembershipPicker({
  onPlanSelect,
  content,
  lifetimePrice,
  isFree,
  freePriceText,
  purchaseButtonText,
  isYearly,
}) {
  const user = useUser()
  let isBuyButtonDisabled = false
  let buttonText = purchaseButtonText

  if (content.plan !== "post_lost_consult") {
    if (user?.membership_plan === "free" && isFree) {
      isBuyButtonDisabled = true
      buttonText = "Your Current Plan"
    }

    if (
      user?.membership_plan === "premium" ||
      user?.membership_plan === "premium_subscription"
    ) {
      isBuyButtonDisabled = true
      if (content.plan === "pre_planning_starter") {
        buttonText = "You’ve Already Upgraded"
      } else {
        buttonText = "Your Current Plan"
      }
    }
  }

  const externalLink = content.external_link ? content.external_link.url : null

  return (
    <div className="w-full md:w-1/2 border border-grey-light shadow-md form-container px-6 py-6 flex flex-col bg-white">
      <div className="text-xl font-semibold tracking-tight leading-snug mb-1">
        {parse(content?.heading?.html)}
      </div>
      <div className="text-sm text-grey-dark leading-normal">
        {parse(content?.subheading?.html)}
      </div>
      <div className="">
        {isFree && (
          <span className="text-3xl font-bold leading-snug">
            {freePriceText}
          </span>
        )}
        {!isFree && lifetimePrice ? (
          <>
            <span className="text-3xl font-bold leading-snug">
              ${(lifetimePrice?.getPriceDetails?.finalPrice / 100).toFixed(2)}
              {isYearly && (
                <span className="text-grey-dark text-xs font-medium">
                  / year
                </span>
              )}
            </span>
          </>
        ) : (
          !isFree && (
            <span className="text-2xl font-semibold leading-snug">
              Loading price...
            </span>
          )
        )}
      </div>

      <div className="mt-4 flex-grow">
        {content?.features?.map((feature) => {
          return (
            <div
              key={feature?.feature?.text}
              className="flex mb-2 leading-tight"
            >
              <div className="w-1/12 pr-2 pt-1 flex-end">
                <div className="checklist-item-checkmark w-3 h-3 inline-block float-right"></div>
              </div>
              <div className="w-11/12 mr-2">
                <p className="text-grey-dark inline-block text-sm leading-normal m-0">
                  {feature?.feature?.text}
                </p>
              </div>
            </div>
          )
        })}
      </div>
      <div className="mt-0 text-center">
        <div className="mt-2 text-center justify-self-end">
          {externalLink ? (
            <Button
              buttonText={buttonText}
              buttonType="btn out-of-app -primary"
              handleOnClick={(event) => {
                event.preventDefault()
                navigate(externalLink)
              }}
              additionalClasses="ml-0 w-full"
              disabled={isBuyButtonDisabled}
              eventName={{
                eventDomain: "Out Of App",
                eventLocation: "For Individuals Pages Membership Picker",
                eventAction: "Click",
                eventActionName: "Get Started",
              }}
            />
          ) : (
            <Button
              buttonText={buttonText}
              buttonType="btn out-of-app -primary w-full"
              handleOnClick={() => {
                onPlanSelect({
                  selectedPlan: lifetimePrice?.getPriceDetails,
                  planContent: content,
                  makePayment: isFree ? false : true,
                })
              }}
              additionalClasses="ml-0 mt-4"
              disabled={isBuyButtonDisabled || (!isFree && !lifetimePrice)}
              eventName={{
                eventDomain: "Out Of App",
                eventLocation: "For Individuals Pages Membership Picker",
                eventAction: "Click",
                eventActionName: "Get Started",
              }}
            />
          )}
        </div>
      </div>
    </div>
  )
}

MembershipPicker.propTypes = {
  onPlanSelect: PropTypes.func,
  content: PropTypes.object,
  annualPrice: PropTypes.object,
  lifetimePrice: PropTypes.object,
  isFree: PropTypes.bool,
  isYearly: PropTypes.bool,
  freePriceText: PropTypes.string,
  purchaseButtonText: PropTypes.string,
}

export default MembershipPicker
