import React from "react"
import Loading from "./svg-components/loading"
import PropTypes from "prop-types"

const LoadingAnimation = ({ text, additionalClass }) => {
  return (
    <div
      className={`flex justify-items-center items-center ${
        additionalClass ? additionalClass : "h-screen"
      }`}
      data-testid="loading-animation"
    >
      <div className="mx-auto my-auto" role="status">
        {text && <p className="font-semibold text-grey">{text}</p>}
        <Loading />
      </div>
    </div>
  )
}
LoadingAnimation.propTypes = {
  text: PropTypes.string,
  additionalClass: PropTypes.string,
}

export default LoadingAnimation
