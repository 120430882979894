import React from "react"
import Modal from "../modal"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import MembershipPickerGroup from "../pricing/membership-picker-group"
import { preparePremiumMembershipsForPicker } from "../../utils/memberships"

function UpgradePaywallModal({ openCheckoutModal, closeModal }) {
  const { allPrismicPaywallModal, allPrismicMembershipPicker } =
    useStaticQuery(graphql`
      query paywallModalQuery {
        allPrismicPaywallModal {
          edges {
            node {
              data {
                title {
                  text
                }
                body {
                  html
                  text
                }
              }
            }
          }
        }
        allPrismicMembershipPicker {
          edges {
            node {
              data {
                heading {
                  html
                  text
                }
                plan
                subheading {
                  html
                  text
                }
                features {
                  active
                  feature {
                    html
                    text
                  }
                }
                trial_button_text {
                  text
                }
                purchase_button_text {
                  text
                }
                external_link {
                  url
                }
              }
            }
          }
        }
      }
    `)

  const { title, body } = allPrismicPaywallModal.edges[0].node.data

  const memebrshipsToShow = preparePremiumMembershipsForPicker(
    allPrismicMembershipPicker?.edges
  )

  return (
    <Modal closeModal={closeModal}>
      <div className="flex-auto p-6 md:px-12 md:py-8">
        <div className="flex flex-col py-2">
          <div className="text-grey-darker font-extrabold leading-tight text-center md:text-left text-3xl md:text-4xl mb-2 flex-grow">
            {title.text}
          </div>
          <p className="body-text mb-4 pb-2 leading-normal">{body.text}</p>
        </div>

        <MembershipPickerGroup
          memberships={memebrshipsToShow}
          onPlanSelect={({ selectedPlan }) => {
            openCheckoutModal(selectedPlan?.priceId)
          }}
        />
      </div>
    </Modal>
  )
}

UpgradePaywallModal.propTypes = {
  openCheckoutModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
}

export default UpgradePaywallModal
