import { gql } from "@apollo/client"

const MUTATION_FAVORITE_ARTICLE = gql`
  mutation favoriteArticle($prismic_article_id: String!) {
    favoriteArticle(prismic_article_id: $prismic_article_id) {
      id
      uid
      title
    }
  }
`

export { MUTATION_FAVORITE_ARTICLE }
