import React, { Component } from "react"
import PropTypes from "prop-types"

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false, error: null }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error: error }
  }

  render() {
    if (this.state.hasError) {
      return (
        <div
          className={`${this.props.additionalClasses} flex flex-row bg-grey-light rounded-xl max-w-body p-20 justify-center w-full mb-4`}
        >
          <i className="material-icons mt-1 mr-2">error_outline</i>
          <p className="text-grey mb-0">
            We&apos;re sorry, something went wrong on our end. Report this
            problem to us
          </p>
          <a
            href="https://www.lantern.co/contact"
            target="_blank"
            rel="noreferrer"
            className="ml-1 underline text-grey"
          >
            here
          </a>
          <p className="text-grey mb-0">.</p>
        </div>
      )
    }
    return <>{this.props.children}</>
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.object,
  additionalClasses: PropTypes.string,
}

export default ErrorBoundary
