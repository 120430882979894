import React from "react"
import PropTypes from "prop-types"
import { MUTATION_FAVORITE_ARTICLE } from "../../graphql/mutations/article"
import { useMutation } from "@apollo/client"
import { useAuth } from "../../context/auth-context"

const SaveArticle = ({ article, user }) => {
  const { refetchUser } = useAuth()
  const [favoriteArticle] = useMutation(MUTATION_FAVORITE_ARTICLE)

  const isBookmarked =  user?.articles?.some(
    (a) =>
      a.prismic_id ===
      (article.node
        ? article.node._meta.id
        : article?.prismic_id
        ? article?.prismic_id
        : article.id)
  )

  const handleSaveArticle = (article) => {
    favoriteArticle({
      variables: {
        prismic_article_id: article.node
          ? article.node._meta.id
          : article?.prismic_id
          ? article?.prismic_id
          : article.id,
      },
    }).then(() => {
      refetchUser()
    })
  }

  const handleOnClick = (event) => {
    event.preventDefault()
    handleSaveArticle(article)
  }

  return (
    <>
      {user !== null && (
        <i
          role="button"
          tabIndex={0}
          className={`${
            isBookmarked ? "text-primary" : "text-grey"
          } material-icons cursor-pointer focus:outline-none`}
          onClick={handleOnClick}
        >
          {/* Check if user has saved articles and if this article has been saved to render icon properly */}
          {isBookmarked ? "bookmark" : "bookmark_border"}
        </i>
      )}
    </>
  )
}

SaveArticle.propTypes = {
  article: PropTypes.object,
  userHasArticles: PropTypes.bool,
  user: PropTypes.object,
  fromList: PropTypes.bool,
}

SaveArticle.defaultProps = {
  article: {},
  userHasArticles: false,
  user: {},
  fromList: false,
}

export default SaveArticle
