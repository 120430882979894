import React from "react"
import PropTypes from "prop-types"
import Modal from "./modal"
import Header from "../layout/header"
import Button from "../button"

function ConfirmModal({
  title,
  description,
  confirmButtonText,
  cancelButtonText,
  onConfirm,
  onCancel,
  confirmButtonDisabled,
  modalClasses,
  answerInput,
  additionalDescription,
  prePlanningWidth,
}) {
  return (
    <Modal
      closeModal={onCancel}
      additionalClasses={`${prePlanningWidth ? "max-w-3xl" : "max-w-5xl"}`}
      modalClasses={`${modalClasses} w-full max-w-lg`}
    >
      <div className="relative p-8 md:px-12 flex-auto text">
        <Header text={title} type="modal" additionalClasses={"text-center"} />
        <div className="body-text text-grey-dark whitespace-pre-line">
          {description}
        </div>
        {additionalDescription && (
          <p className="body-text text-grey-dark italic mt-3">
            {additionalDescription}
          </p>
        )}
        <p className="body-text">{answerInput}</p>
        <div className="flex flex-wrap justify-center">
          <Button
            buttonText={confirmButtonText ?? "Confirm"}
            buttonType="btn in-app -primary"
            additionalClasses="ml-0 mt-0 mb-0 mr-0 items-center w-full md:w-56"
            disabled={confirmButtonDisabled}
            handleOnClick={onConfirm}
          />
          <Button
            buttonText={cancelButtonText ?? "Cancel"}
            buttonType="btn ghost-button"
            handleOnClick={onCancel}
            additionalClasses="m-0"
          />
        </div>
      </div>
    </Modal>
  )
}

ConfirmModal.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  confirmButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  confirmButtonDisabled: PropTypes.bool,
  prePlanningWidth: PropTypes.bool,
  modalClasses: PropTypes.string,
  answerInput: PropTypes.object,
  additionalDescription: PropTypes.string,
  cancelButtonClasses: PropTypes.string,
}

ConfirmModal.defaultProps = {
  cancelButtonClasses: "default-button bg-grey-light mt-0 ml-0 md:mt-5 bg-c",
}

export default ConfirmModal
