import axios from "axios"
import fileDownload from "js-file-download"
import { getToken } from "./auth"
import moment from "moment"

export const downloadPDF = async ({ topic, plan, alert }) => {
  let body = topic ? { topicId: topic.id } : {}
  return axios
    .post(
      `${process.env.GATSBY_LANTERN_REST_URL}/preplan/${plan.id}/print`,
      body,
      {
        headers: { Authorization: `Bearer ${getToken()}` },
        responseType: "blob",
      }
    )
    .then((response) => {
      fileDownload(
        response.data,
        `${plan.name ?? "planahead"}-${moment().format("YYYY-MM-DD")}.pdf`
      )
    })
    .catch(() => {
      alert.error("Something went wrong as we were creating the PDF.")
    })
}

export const downloadZip = async ({ plan, topic, alert }) => {
  axios
    .get(
      `${process.env.GATSBY_LANTERN_REST_URL}/preplan/${plan.id}/topic/${topic.id}/download`,
      {
        headers: { Authorization: `Bearer ${getToken()}` },
        responseType: "blob",
      }
    )
    .then((response) => {
      fileDownload(
        response.data,
        `${topic.name}--${plan.name}--${moment().format("YYYY-MM-DD")}.zip`
      )
    })
    .catch((error) => {
      console.log(error)
      alert.error("Something went wrong as we were creating the Zip.")
    })
}

export const downloadPlanZip = async ({ plan, alert }) => {
  axios
    .get(`${process.env.GATSBY_LANTERN_REST_URL}/preplan/${plan.id}/download`, {
      headers: { Authorization: `Bearer ${getToken()}` },
      responseType: "blob",
    })
    .then((response) => {
      fileDownload(
        response.data,
        `${plan.name}--${moment().format("YYYY-MM-DD")}.zip`
      )
    })
    .catch((error) => {
      console.log(error)
      alert.error("Something went wrong as we were creating the Zip.")
    })
}
