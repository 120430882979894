const preparePremiumMembershipsForPicker = (prismicEdges) => {
  return prismicEdges
    ?.map(({ node }) => {
      return node.data
    })
    .filter((membership) =>
      ["pre_planning_starter", "pre_planning_premium"].includes(membership.plan)
    )
    .sort((a) => {
      return a.plan === "pre_planning_starter" ? -1 : 0
    })
}

const prepareFreeMembershipsForPicker = (prismicEdges) => {
  return prismicEdges
    ?.map(({ node }) => {
      return node.data
    })
    .filter((membership) =>
      ["post_loss_light", "post_lost_consult"].includes(membership.plan)
    )
    .sort((a) => {
      return a.plan === "post_loss_light" ? -1 : 0
    })
}

export { preparePremiumMembershipsForPicker, prepareFreeMembershipsForPicker }
