import React, { useEffect, useRef } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"

const portalRoot =
  typeof document !== `undefined` ? document.getElementById("portal") : null

function Modal({
  children,
  closeModal,
  disableClose,
  additionalClasses,
  modalClasses,
  isGlobalSearch,
}) {
  const el = useRef(document.createElement("div"))

  useEffect(() => {
    portalRoot.appendChild(el.current)
    return () => {
      portalRoot.removeChild(el.current)
    }
  }, [])

  if (!el) {
    return null
  }

  return ReactDOM.createPortal(
    <React.Fragment>
      <div
        className={`flex overflow-x-hidden fixed inset-0 z-50 outline-none focus:outline-none 
          ${isGlobalSearch ? "justify-end" : "justify-center"}`}
        onClick={closeModal}
      >
        <div
          className={`relative ${
            isGlobalSearch
              ? "mr-4 mt-4 w-1/3"
              : `mx-auto ${modalClasses} mt-8 md:max-w-3xl`
          }`}
          onClick={(event) => {
            event.stopPropagation()
          }}
        >
          {!disableClose && !isGlobalSearch && (
            <div className={"absolute z-50 right-3 md:right-0"}>
              <button
                className="material-icons flex items-center py-2 px-3 shadow-md rounded-full justify-center border-none focus:outline-none text-primary font-bold text-sm bg-white"
                onClick={closeModal}
              >
                close
              </button>
            </div>
          )}
          <div
            className={`overflow-y-hidden ${
              isGlobalSearch ? "form-container" : "mt-10"
            }`}
          >
            <div
              className={`w-auto md:max-w-4xl mx-3 overflow-y-auto overflow-x-hidden md:mx-auto 
              ${isGlobalSearch ? "" : "form-container"}`}
            >
              <div
                className={`relative flex flex-col w-full overflow-y-auto bg-white outline-none focus:outline-none 
                ${additionalClasses ?? ""} ${isGlobalSearch ? " pt-8" : ""}`}
              >
                {children}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={
          isGlobalSearch
            ? "fixed inset-0 z-40"
            : "opacity-70 fixed inset-0 z-40 bg-grey-lighter"
        }
      />
    </React.Fragment>,
    el.current
  )
}

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  additionalClasses: PropTypes.string,
  modalClasses: PropTypes.string,
  isGlobalSearch: PropTypes.bool,
  closeModal: PropTypes.func,
  disableClose: PropTypes.bool,
}

Modal.defaultProps = {
  modalClasses: "md:w-3/4",
}

export default Modal
